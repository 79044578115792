import {RemixBrowser, useLocation, useMatches} from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import {startTransition, StrictMode, useEffect} from "react"
import {hydrateRoot} from "react-dom/client"

if (window.app.sentryDsn) {
  Sentry.init({
    dsn: window.app.sentryDsn,
    tracesSampleRate: 1,
    release: window.app.version ?? undefined,
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches,
        ),
      }),
    ],
  })
}

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    )
  })
}

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1)
}
